.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(1,86,125);
}

.image {
  margin-top: 2%;
  width:40vw;
  height:80vh;
}
@media only screen and (max-width: 1100px) {
  .image {
    margin-top: 10px;
    width:100vw;
    height:80vh;
  }
}
.contact {
  position: absolute;
  bottom:0;
  margin-bottom: 4%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contactText {
  color:white;
  bottom:0;
  text-align: center;
  font-size: 32px;
}

